var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailInfo",
                attrs: { title: "설비 기본정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _vm.editable &&
                  !_vm.popupParam.multiple &&
                  _vm.popupParam.noPlan === "N"
                    ? _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-label-text", {
                            attrs: {
                              title: "정비부서",
                              value: _vm.result.deptNm,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editable && !_vm.popupParam.multiple
                    ? _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-label-text", {
                            attrs: {
                              title: "설비유형",
                              value: _vm.result.equipmentType,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editable && !_vm.popupParam.multiple
                    ? _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-label-text", {
                            attrs: {
                              title: "설비코드",
                              value: _vm.result.equipmentCd,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editable && !_vm.popupParam.multiple
                    ? _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-label-text", {
                            attrs: {
                              title: "설비명",
                              value: _vm.result.equipmentNm,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.popupParam.multiple &&
                  _vm.popupParam.noPlan === "N"
                    ? _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-label-text", {
                            attrs: {
                              title: "정비유형",
                              value: _vm.result.safFacilityNm,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.popupParam.multiple &&
                  _vm.popupParam.noPlan === "N"
                    ? _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-label-text", {
                            attrs: {
                              title: "정비부서 입회자",
                              value: _vm.result.chkUserNm,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.popupParam.multiple &&
                  _vm.popupParam.noPlan === "N"
                    ? _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-label-text", {
                            attrs: {
                              title: "정비예정일",
                              value: _vm.result.chkSchYmd,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.popupParam.multiple &&
                  _vm.popupParam.noPlan === "N"
                    ? _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-label-text", {
                            attrs: {
                              title: "정비예정자",
                              value: _vm.result.chkUserNm,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editable && !_vm.popupParam.multiple
                    ? _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-label-text", {
                            attrs: { title: "이전정비일", value: _vm.result.a },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editable && !_vm.popupParam.multiple
                    ? _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-label-text", {
                            attrs: {
                              title: "정비주기",
                              value: _vm.result.cycle,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editable && !_vm.popupParam.multiple
                    ? _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-label-text", {
                            attrs: {
                              title: "차기법정예정월",
                              value: _vm.result.b,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editable && !_vm.popupParam.multiple
                    ? _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-label-text", {
                            attrs: { title: "관련법규", value: _vm.result.c },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "col-12" }),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: {
                  title: "설비 정비결과",
                  topClass: "topcolor-orange",
                  bgClass: "",
                },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-3" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          label: "실제정비자",
                          disabled: true,
                          name: "chkRsltSmry",
                        },
                        model: {
                          value: _vm.result.chkUserNm,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "chkUserNm", $$v)
                          },
                          expression: "result.chkUserNm",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-3" },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          editable: _vm.editable,
                          label: "정비완료일",
                          disabled: true,
                          name: "chkSchCompleYmd",
                        },
                        model: {
                          value: _vm.result.chkSchCompleYmd,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "chkSchCompleYmd", $$v)
                          },
                          expression: "result.chkSchCompleYmd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-3" },
                    [
                      _c("c-select", {
                        attrs: {
                          editable: _vm.editable,
                          comboItems: _vm.chkRsltYnItems,
                          type: "edit",
                          itemText: "codeName",
                          itemValue: "code",
                          disabled: true,
                          name: "chkRsltYn",
                          label: "정비완료여부",
                        },
                        model: {
                          value: _vm.result.chkRsltYn,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "chkRsltYn", $$v)
                          },
                          expression: "result.chkRsltYn",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-3" },
                    [
                      _c("c-select", {
                        attrs: {
                          editable: _vm.editable,
                          comboItems: _vm.chkResultItems,
                          type: "edit",
                          itemText: "codeName",
                          itemValue: "code",
                          name: "chkResult",
                          disabled: true,
                          label: "정비후 점검결과",
                        },
                        model: {
                          value: _vm.result.chkResult,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "chkResult", $$v)
                          },
                          expression: "result.chkResult",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-3" },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          editable: _vm.editable,
                          label: "정비시간",
                          disabled: true,
                          name: "chkMaintenanceYmd",
                        },
                        model: {
                          value: _vm.result.chkMaintenanceYmd,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "chkMaintenanceYmd", $$v)
                          },
                          expression: "result.chkMaintenanceYmd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-3" },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          editable: _vm.editable,
                          label: "운전정지시간",
                          disabled: true,
                          name: "chkStopYmd",
                        },
                        model: {
                          value: _vm.result.chkStopYmd,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "chkStopYmd", $$v)
                          },
                          expression: "result.chkStopYmd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          label: "정비자 자격사항",
                          disabled: true,
                          name: "chkUserQualify",
                        },
                        model: {
                          value: _vm.result.chkUserQualify,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "chkUserQualify", $$v)
                          },
                          expression: "result.chkUserQualify",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "정비원인",
                          disabled: true,
                          name: "chkRsltCause",
                        },
                        model: {
                          value: _vm.result.chkRsltCause,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "chkRsltCause", $$v)
                          },
                          expression: "result.chkRsltCause",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "정비내용",
                          disabled: true,
                          name: "chkRsltContent",
                        },
                        model: {
                          value: _vm.result.chkRsltContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "chkRsltContent", $$v)
                          },
                          expression: "result.chkRsltContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "정비결과요약",
                          disabled: true,
                          name: "chkRsltSmry",
                        },
                        model: {
                          value: _vm.result.chkRsltSmry,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "chkRsltSmry", $$v)
                          },
                          expression: "result.chkRsltSmry",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "관리자의 검토의견",
                          disabled: true,
                          name: "managerOpinion",
                        },
                        model: {
                          value: _vm.result.managerOpinion,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "managerOpinion", $$v)
                          },
                          expression: "result.managerOpinion",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }